import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'

import { formatDate } from 'src/utils/dateUtils'

import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

type NoteProps = {
	date: string
	from: string
	to: string
	subject: string
	content: string
	createdBy: string
	isExternal: boolean
	isFirstNote: boolean
	isLastNote: boolean
	attachmentsFile: AttachmentDTO[] | null
}

type AttachmentDTO = {
	fileName: string
	documentBody: string
	mimetype: string
	filesize: number
}

const Note: FC<NoteProps> = ({
	date,
	from,
	to,
	subject,
	content,
	createdBy,
	//  isExternal,
	isFirstNote,
	isLastNote,
	attachmentsFile = [],
}) => {

	const generateDownloadLink = (fileName: string, documentBody: string, mimetype: string): string => {
		const byteCharacters = atob(documentBody);
		const byteNumbers = new Uint8Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const blob = new Blob([byteNumbers], { type: mimetype });
		return URL.createObjectURL(blob);
	};

	return (
		<div className={`note-container row m-0 ${isFirstNote ? 'mt-5' : ''} ${isLastNote ? 'mb-10' : 'mb-5'}`}>
			<div className="col-3 d-flex flex-column align-items-start">
				<div className="note-date">{formatDate(date, '/', true)}</div>
			</div>
			<div className="col-9 d-flex flex-column">
				<div className="note-header d-flex align-items-center">
					<span>{from}</span>
					<FontAwesomeIcon icon={faLongArrowAltRight} fontSize={20} className="mx-3" />
					<span>{to}</span>
				</div>
				<div className="note-subject mt-3">{subject}</div>
				<div className="note-content mt-2">{content}</div>
				<div className="note-files mt-2">
					{attachmentsFile &&
						attachmentsFile?.map((attachment, index) => (
							<div key={index} className="attachment-item">
								<a
									href={generateDownloadLink(attachment.fileName, attachment.documentBody, attachment.mimetype)}
									download={attachment.fileName}
									className="attachment-link"
								>
									{attachment.fileName}
								</a>
							</div>
						))}
				</div>
				<div className="note-footer mt-3">{createdBy}</div>
			</div>
		</div>
	)
}

export { Note }
